import React from "react";
import styled from "styled-components";

import { baseInterpolator, convertPrice, getPricesFromBundleNew } from "@utils";
import { useAppSelector } from "../../../../../app/hooks";
import { BundlePB, PageContext } from "src/components/slices/types";

const PriceContainer = styled.div<{ selected: boolean }>`
    ${({ selected }) => `
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: start;
        grid-column: 2 / 3;
        grid-row: 1 / 4;
        text-align: right;
        row-gap: 8px;

        ${selected && `
            * {
                color: var(--light-green);
            }
        `}
        
        @media screen and (min-width: 768px) {
            margin: 0 0 24px;
        }
    `}
`

const HardwareName = styled.span`
    margin: 0 6px 0 0;
    font-size: 11px;
`

const OriginalPrice = styled.p`
    display: inline-block;
    font-size: 12px;
    margin-right: 4px;

    span {
        text-decoration: line-through;
    }
`

const FreeMonth = styled.p`
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    margin-right: 4px;
`

const PriceFootnote = styled.p`
    font-size: 10px;
    line-height: 16px;
    text-decoration: none;
    margin-right: 4px;
`

const Price = styled.p`
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
`

interface SubscriptionBlockPricesProps {
    bundle: BundlePB;
    locale: string;
    currency: string;
    translations: PageContext['translations'];
};

const capitalize = (str: string): string  => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
const getPriceString = (str: string, currency: string) => str + capitalize(currency);

const SubscriptionBlockPrices = ({
    bundle,
    locale,
    translations,
}: SubscriptionBlockPricesProps) => {
    const [selectedBundle, currency ] = useAppSelector(state => [state.product.selectedBundle, state.cart.currency]);
    const prices = getPricesFromBundleNew(locale, currency, bundle);

    const selected = bundle?.bundleId === selectedBundle;
    
    // @ts-ignore
    const shippingPrice = bundle?.[getPriceString('priceShipping', currency )] || 0;

    // @ts-ignore
    const trialPeriodInDays = bundle?.[getPriceString('recurringTrialPeriodDays', currency )] || 0;

    // @ts-ignore
    const priceOneTime = bundle?.[getPriceString('priceAmountOneTimeCurrent', currency )] || 0;
    // @ts-ignore
    const crossedPriceOneTime = bundle?.[getPriceString('priceAmountOneTimeCrossed', currency )] || 0;

    // @ts-ignore
    const priceRecurring = bundle?.[getPriceString('priceAmountRecurringCurrent', currency )] || 0;

    // @ts-ignore
    const priceAfterTrial = bundle?.[getPriceString('priceAmountPayAfterTrialCurrent', currency )] || 0;

    // @ts-ignore
    const pricePayNow = bundle?.[getPriceString('priceAmountPayNowCurrent', currency )] || 0;
    // @ts-ignore
    const crossedPricePayNow = bundle?.[getPriceString('priceAmountPayNowCrossedEur', currency )] || 0;

    const showHardwarePrefix = crossedPriceOneTime > priceOneTime && bundle?.tags?.includes('toothbrush');
    const showPriceAfterTrial = priceAfterTrial > 0;
    const showTrialPeriod = trialPeriodInDays > 0;
    const showShippingMessage = shippingPrice > 0;

    const hardwareHasDiscount = !!bundle?.isCrossedPriceVisible;

    const shipping = convertPrice((shippingPrice || 0), locale, currency);

    const recurringText = showTrialPeriod ? translations.free_month_recurring : translations.recurring;
    const recurring = convertPrice(
        (showTrialPeriod ? priceAfterTrial : priceRecurring) || 0,
        locale,
        currency
    );

    return (
        <PriceContainer selected={selected}>
            <div>
                {showHardwarePrefix && <HardwareName>{translations.checkout.brush}</HardwareName>}
                {hardwareHasDiscount && (
                    <OriginalPrice>
                        {/* {`${translations.retail_selling_price} `} */}
                        <span>
                            {convertPrice((crossedPriceOneTime > 0 ? crossedPriceOneTime : crossedPricePayNow), locale, currency)}
                        </span>
                    </OriginalPrice>
                )}
                <Price>{convertPrice((priceOneTime > 0 ? priceOneTime : pricePayNow), locale, currency)}</Price>
            </div>
            {showTrialPeriod && (
                <FreeMonth>
                    {baseInterpolator(translations.free_month, {
                        ...prices,
                        duration_in_days: trialPeriodInDays,
                    })}
                </FreeMonth>
            )}
            {showPriceAfterTrial && (
                <PriceFootnote>
                    {baseInterpolator(recurringText, {
                            ...prices,
                            price: recurring,
                        }
                    )}
                </PriceFootnote>
            )}
            {showShippingMessage && (
                <PriceFootnote>
                    {baseInterpolator(
                        translations.base_product_shipping_line,
                        { shipping }
                    )}
                </PriceFootnote>
            )}
        </PriceContainer>
    )
}

export default SubscriptionBlockPrices;
