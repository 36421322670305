import React, { useEffect, useState } from "react"
import styled from "styled-components";
import VariantSelectorWithFilter from './form-snippets/variant-selector-with-filter';
import SubscriptionSelectorWithFilter from "./form-snippets/subscription-selector-with-filter";
import AddToCart from "./form-snippets/add-to-cart";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import Client from "../../../../utils/playbrush-client/client";
import { setCart } from "../../../../app/slices/cart-reducer";
import { getThankYouPage, langToCountry, langToLanguage, logEcommerceEvent } from "@utils";
import { setBundles, setInitialState } from "../../../../app/slices/filter-reducer";
import { BundlePB, BundlesConfig, PageContext, Settings } from "../../../slices/types";
import { getTrackingItems } from "../../../../utils/product";
import { FrontendLineData } from "../../../../utils/playbrush-client/types";

const Form = styled.form`
	
	@media screen and (min-width: 768px) {

	}
`

const Spacer = styled.div`
	
	@media screen and (min-width: 768px) {
        margin: 0 0 40px;
        border: 1px solid var(--light-green);
        opacity: 0.2;
	}
`

const Footer = styled.footer`
    
    button {
        width: 100%;
        margin-bottom: 16px;
    }

	@media screen and (min-width: 768px) {

	}
`

const ShippingMessage = styled.div`
	
	@media screen and (min-width: 768px) {

	}
`

const getTrackingDataFromSearchParams = (location: { search?: string }): [number, string, string, number] => {
    const trackingPositionMatchData = ((location.search || '') as string)?.match(/position=([^&]+)/);
    const trackingPosition = parseInt(trackingPositionMatchData ? trackingPositionMatchData[1] : '0');

    const trackingSectionIdMatchData = ((location.search || '') as string)?.match(/section_id=([^&]+)/);
    const trackingSectionId = trackingSectionIdMatchData ? trackingSectionIdMatchData[1] : 'na';

    const trackingSectionNameMatchData = ((location.search || '') as string)?.match(/section_name=([^&]+)/);
    const trackingSectionName = trackingSectionNameMatchData ? trackingSectionNameMatchData[1] : 'na';

    const preselectMatchData = ((location.search || '') as string)?.match(/preselect=([^&]+)/);
    const preselect = parseInt(preselectMatchData ? preselectMatchData[1] : '1');

    return [trackingPosition, trackingSectionId, trackingSectionName, preselect];
}

const ProductFormWithFilter = ({
    filterString,
    translations,
    location,
    settings,
    shouldShowCompareBrushes,
    brushModal,
}: {
    filterString?: string;
    translations: PageContext['translations'];
    location: Record<string, unknown>;
    settings: Settings;
    shouldShowCompareBrushes: boolean;
    showFloatingSelector: boolean;
    brushModal: any;
}) => {
    const [trackingPosition, trackingSectionId, trackingSectionName, preselect] = getTrackingDataFromSearchParams(location);
    const [cart, bundles, selectedColor, selectedBrush, selectedBundle, lang, audience] = useAppSelector(state => [
        state.cart,
        state.filter.bundles,
        state.filter.selectedColor,
        state.filter.selectedBrush,
        state.filter.selectedBundle,
        state.general.lang,
        state.general.audience,
    ]);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState<BundlesConfig>();

    const filters = JSON.parse(filterString || '{}');
    const productType = filters.product;
    const allowedTags = filters.allowed_tags;
    const disallowedTags = filters.disallowed_tags;

    const productConfig = config?.product_categories.find((category) => category.category === filters.product);

    const colorConfig = productConfig?.tag_categories.find((category) => category.category === 'colors');
    const colors = allowedTags?.filter((allowedTag: string) => colorConfig?.tags.includes(allowedTag)) || [];
    
    const brushConfig = productConfig?.tag_categories.find((category) => category.category === 'brush');
    const brushes = allowedTags?.filter((allowedTag: string) => brushConfig?.tags.includes(allowedTag)) || [];

    const sizeConfig = productConfig?.tag_categories.find((category) => category.category === 'volume');
    const sizes = allowedTags?.filter((allowedTag: string) => sizeConfig?.tags.includes(allowedTag)) || [];

    const typeConfig = productConfig?.tag_categories.find((category) => category.category === 'type');
    const type = allowedTags?.find((allowedTag: string) => typeConfig?.tags.includes(allowedTag));

    // const preselectIndex =  preselect !== 0 ? preselect - 1 : 0;

    const bundle = bundles?.find((bundle: BundlePB) => bundle.bundleId === selectedBundle);
    const priceSuffix = cart.currency === 'EUR' ? 'Eur' : 'Gbp';
    const price = bundle?.[`priceAmountPayNowCurrent${priceSuffix}`];

    const init = () => {
        dispatch(setInitialState({
            productType,
            colors,
            brushes,
            hardwareType: type ? type : null,
            selectedColor: colors && colors.length ? colors[0] : null,
            selectedBrush: brushes && brushes.length ? brushes[0] : null,
            selectedSize: sizes && sizes.length ? sizes[0] : null,
            disallowedTags: disallowedTags || [],
        }))
    }

    useEffect(() => {
        init()
    }, [config, bundles, filterString])

    useEffect(() => {
        const currentBundle = bundles?.find((bundle: any) => bundle.bundleId === selectedBundle)


        if (currentBundle) {
            const trackingContext: FrontendLineData['trackingContext'] = {
                discount: ((bundle?.[`priceAmountPayNowCrossed${priceSuffix}`] || 0) - (bundle?.[`priceAmountPayNowCurrent${priceSuffix}`] || 0)) / 100,
                index: trackingPosition,
                item_brand: currentBundle.tags.includes('subscription') ? 'Plan' : 'Smart Toothbrush',
                item_category:currentBundle.tags.includes('smart_sonic') ? 'Playbrush Smart Sonic' : 'Playbrush Smart',
                item_id: selectedBundle || currentBundle.bundleId,
                item_list_id: trackingSectionId,
                item_list_name: trackingSectionName,
                item_name: currentBundle.name || 'na',
                item_variant: `${
                    selectedColor ? selectedColor : (colors?.[0] || 'na')
                } / ${
                    selectedBrush ? selectedBrush : (brushes?.[0] || 'na')
                }`,
                price: (price || 0) / 100,
                quantity: 1,
            }

            logEcommerceEvent([trackingContext], 'view_item');
        }
        

    }, [selectedBundle])

    useEffect(() => {
        fetch('/bundles/data.json').then(data => data.json()).then(async (data: BundlePB[]) => {
            const config = await fetch('/bundles/config.json').then(res => res.json()).then((newBundles: BundlesConfig) => newBundles);
            dispatch(setBundles(data));
            setConfig(config);
        });
    }, []);

    const onSubmit: any = async (e: any) => {
        e.preventDefault()
        setLoading(true);

        let payload: { lineId: string, quantity: number, frontendLineData?: string }[] = cart.lineItems.map((lineItem) => ({
            lineId: lineItem.lineId,
            quantity: lineItem.quantity,
            frontendLineData: JSON.stringify(lineItem.frontendLineData),
        }));

        const getItemBrand = () => {
            if (bundle?.tags.includes('hardware')) {
                return 'Smart toothbrush'
            } else if (bundle?.tags.includes('subscription')) {
                return 'Plan'
            } else {
                return 'Other'
            }
        }

        const getItemCategory = () => {
            if (bundle?.tags.includes('smart_sonic')) {
                return 'Playbrush Smart Sonic'
            } else if (bundle?.tags.includes('smart_one')) {
                return 'GUM Smart One'
            } else if (bundle?.tags.includes('smart')) {
                return 'Playbrush Smart'
            } else {
                return 'Non brush item'
            }
        }

        const trackingContext: FrontendLineData['trackingContext'] = {
            discount: ((bundle?.[`priceAmountPayNowCrossed${priceSuffix}`] || 0) - (bundle?.[`priceAmountPayNowCurrent${priceSuffix}`] || 0)) / 100,
            index: trackingPosition,
            item_brand: getItemBrand(),
            item_category: getItemCategory(),
            item_id: bundle?.bundleId as string,
            item_list_id: trackingSectionId,
            item_list_name: trackingSectionName,
            item_name: bundle?.name || 'na',
            item_variant: `${selectedColor} / ${selectedBrush}`,
            price: (price || 0) / 100,
            quantity: 1,
        }

        const frontendLineData = {
            trackingContext,
            productContext: {
                page: location?.pathname + window?.location?.search || '',
            },
        };

        const bundleId = bundle?.bundleId || '';
        const lineItemIndex = payload.findIndex((lineItem) => lineItem.lineId === bundleId);
        const lineItemExists = lineItemIndex >= 0;

        if (lineItemExists) {
            payload[lineItemIndex].quantity += 1;
            frontendLineData.trackingContext.quantity = payload[lineItemIndex].quantity;
            payload[lineItemIndex].frontendLineData = JSON.stringify(frontendLineData);
        } else {
            payload.push({
                lineId: bundleId,
                quantity: 1,
                frontendLineData: JSON.stringify(frontendLineData),
            })
        }

        const frontendData = {
            ...(cart.frontendData as unknown) as Record<string, unknown>,
            thankYouPage: getThankYouPage(cart.lineItems, settings, audience),
        };

        const newCart = await Client.addProduct({
            itemList: payload,
            cartId: cart.cartId,
            couponCode: cart.couponCode,
            frontendData: JSON.stringify(frontendData),
            currency: cart.currency,
            language: langToLanguage(lang),
            country: langToCountry(lang),
        })


        if (newCart) {
            newCart.open = true;
            dispatch(setCart(newCart))

            const trackingItems = getTrackingItems(newCart);
            logEcommerceEvent(trackingItems, 'add_to_cart');
        };

        setLoading(false);
    };

    return (
        <Form onSubmit={onSubmit}>
            {config && (
                <VariantSelectorWithFilter
                    config={config}
                    translations={translations}
                    shouldShowCompareBrushes={shouldShowCompareBrushes}
                    brushModal={brushModal}
                />
            )}
            <Spacer />
            {config && (
                <SubscriptionSelectorWithFilter
                    translations={translations}
                    config={config}
                    filter={filterString}
                    bundles={bundles}
                    locale={cart.locale}
                    location={location}
                    currency={cart.currency}
                />
            )}
            <Footer>
                <AddToCart isLoading={loading} price={price as number} buttonText={translations.add_to_cart} />
                {!bundle?.tags.includes('brush_heads_no_sub') && bundle?.tags.includes('one_time') && (
                    <ShippingMessage>
                        {translations.shipping_message}
                    </ShippingMessage>
                )}
            </Footer>
        </Form>
    )
}

export default ProductFormWithFilter;