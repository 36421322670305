import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { PageContext } from "../../slices/types";
import Client from "../../../utils/playbrush-client/client";
import Button from "../button";
import { RichText } from "prismic-reactjs";
import { OrderContent, ReferralContent } from "../../../utils/playbrush-client/types";
import ReferralCode, { CodeButton } from "./referral-code";
import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
} from 'react-share';
import { EmailShare, FacebookShare, LinkShare, WhatsappShare } from "../../icons/share-icons";
import { getQueryParam, copyToClipboard, hasRichText } from "@utils";
import { PrismicRichText } from "@prismicio/react";

const Box = styled.form`
    background-color: #ffffff;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    margin: 0 0 40px;

    & > ${CodeButton} {
        margin: 0 0 32px;
    }

    & > button + button {
        margin-left: 40px;
    }
`

const Heading = styled.h4`
    margin: 0 0 16px;
`;

const Body = styled.div`
    margin: 0 0 16px;
`;

const Optin = styled.label`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
    margin: 0 0 16px;
`;

const Input = styled.input`
    margin: 4px 16px 0 0;
    flex: 0 0 13px;
`;

const RtText = styled.div`
    flex: 0 0 90%;
`;

const Text = styled.span`
    flex: 0 0 90%;
`;

const OptinError = styled.p`
    background-color: rgba(255, 0, 0, .2);
    color: rgb(255, 0, 0);
    padding: 8px 16px;
    line-height: 1.4;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    margin: 8px 0 0;
    grid-column: 1 / 3;
`

const ShareIcons = styled.div`
    display: flex;
    justify-content: space-evenly;
`

const LinkShareButton = styled.button`
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;

    &:active {
        background-color: #EAF0F6;
        outline: 8px solid #EAF0F6;
        filter: brightness(0.8);
    }
`

interface ReferralBoxProps {
    data: Queries.PrismicThankYouPageDataType;
    order?: OrderContent;
    translations?: PageContext['translations'];
    settings?: PageContext['settings'];
    lang?: string;
    location: Record<string, unknown>;
}

const ReferralBox = (props: ReferralBoxProps) => {
    const { data, location } = props;
    const [loading, setLoading] = useState(false);
    const [referral, setReferral] = useState<ReferralContent>();
    const [optinValue, setOptinValue] = useState(false);
    const [optinError, setOptinError] = useState(false);

    const [link, setLink] = useState('');

    const [referralBodyHasRichText, referralBodyRichText] = hasRichText(data?.referral_body?.richText);
    const [referralSuccesBodyHasRichText, referralSuccessBodyRichText] = hasRichText(data?.referral_succes_body?.richText);
    const [buttonHasRichText, buttonRichText] = hasRichText(data?.referral_button_text?.richText);
    const [optinHasRichText, optinRichText] = hasRichText(data?.referral_optin_rt?.richText);

    const interpolateText = (htmlString: string): string => htmlString
        .replace(/<br\s{0,1}\/>/g, '\n')
        .replace(/<\/{0,1}[^>]>/g, '')
        .replace(/{\s{0,}first_name\s{0,}}/g, referral?.firstName || '')
        .replace(/{\s{0,}last_name\s{0,}}/g, referral?.lastName || '')
        .replace(/{\s{0,}coupon_text\s{0,}}/g, referral?.couponText || '')
        .replace(/{\s{0,}coupon_code\s{0,}}/g, referral?.couponcode || '') + '\n';

    const interpolatedFacebookShareMessage = interpolateText(data?.facebook_share_message?.html || '');
    const interpolatedWhatsappShareMessage = interpolateText(data?.whatsapp_share_message?.html || '');
    const interpolatedEmailShareMessage = interpolateText(data?.email_share_message?.html || '');

    const orderId = getQueryParam(location, 'orderId');
    // const userId = getQueryParam(location, 'userId');
    const couponCode = getQueryParam(location, 'c');

    const handleInput = () => {
        setOptinError(false);
        setOptinValue(!optinValue);
    }

    const getOrderReferral = async (e: any) => {
        e.preventDefault();
        if (!optinValue || !orderId) {
            setOptinError(true);
            return;
        } else { setOptinError(false) }

        setLoading(true);
        const referral = await Client.getOrCreateReferralCode({ orderId });

        setReferral(referral);
        setLoading(false);
    }

    const getReferral = async (e: any) => {
        e.preventDefault();
        if (!optinValue || !couponCode) {
            setOptinError(true);
            return;
        } else { setOptinError(false) }

        setLoading(true);
        const referral = await Client.getReferralLink({ couponCode: couponCode as string }); // userId: userId as string
        setReferral(referral);
        setLoading(false);
    }

    const copy = async () => {
        await copyToClipboard(link);
    }

    useEffect(() => {
        
        const searchParam = referral?.couponLinkDiscountKidsWebsite?.replace(/^[^?]+/gm, '') || `?c=${referral?.couponcode}`;
        setLink(`${location.origin}${data?.link_to_referral_landing?.url || ''}/${searchParam}`);
    }, [referral])

    let handleSubmit = async (e: any) => await new Promise(res => res(null));

    if (orderId) {
        handleSubmit = getOrderReferral;
    } else if (couponCode) {
        handleSubmit = getReferral;
    }

    return (
        <>
            <Box onSubmit={handleSubmit}>
                {!referral ? (
                    <>
                        <Heading>{data?.referral_heading}</Heading>
                        {(data?.code_placeholder) && (
                            <ReferralCode placeholder={data.code_placeholder as string} useIcon={false} />
                        )}
                        {referralBodyHasRichText && (
                            <Body className="rt">
                                {RichText.render(referralBodyRichText)}
                            </Body>
                        )}
                        <Optin>
                            <Input
                                type="checkbox"
                                checked={optinValue}
                                onChange={handleInput}
                            />
                            {optinHasRichText ? (
                                <RtText>
                                    <PrismicRichText field={data?.referral_optin_rt?.richText} />
                                </RtText>
                            ) : (
                                <Text>
                                    {data?.referral_optin}
                                </Text>
                            )}
                            {optinError && (
                                <OptinError>
                                    {data?.referral_optin_error}
                                </OptinError>
                            )}
                        </Optin>
                        {buttonHasRichText && (
                            <Button type="submit" buttonStyle={'filled'} loading={loading}>
                                {RichText.render(buttonRichText)}
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Heading>{data?.referral_heading}</Heading>
                        {(referral?.couponcode) && (
                            <ReferralCode code={referral.couponcode} />
                        )}
                        {referralSuccesBodyHasRichText && (
                            <Body className="rt">
                                {RichText.render(referralSuccessBodyRichText)}
                            </Body>
                        )}
                        <ShareIcons>
                            <FacebookShareButton
                                url={link}
                                quote={interpolatedFacebookShareMessage}
                            >
                                <FacebookShare />
                            </FacebookShareButton>
                            <WhatsappShareButton
                                url={link}
                                title={interpolatedWhatsappShareMessage}
                                separator={'\n'}
                            >
                                <WhatsappShare />
                            </WhatsappShareButton>
                            {data?.email_share_subject && data?.email_share_message && (
                                <EmailShareButton
                                    url={link}
                                    subject={data.email_share_subject || ''}
                                    body={interpolatedEmailShareMessage || ''}
                                >
                                    <EmailShare />
                                </EmailShareButton>
                            )}
                            <LinkShareButton type="button" onClick={copy}>
                                <LinkShare />
                            </LinkShareButton>
                        </ShareIcons>
                    </>
                )}
            </Box>
        </>
    )
};

export default ReferralBox;