import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import BundleListWithFilter from "./bundle-list-with-filter";
import TagSectionWithFilter from "./tag-section-with-filter";

import { getCleanPrismicBundleTags } from "@utils";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setBundle } from "../../../../../app/slices/filter-reducer";
import ManualBundleList from "./manual-bundle-list";
import SubscriptionBlockPricesWithFilter from "./subscription-block-prices-with-filter";
import { getOptionFromLang } from "../../../../snippets/language/language-select/utils/get-option-from-lang";
import { BundlePB, PageContext } from "../../../../../components/slices/types";

const Label = styled.label<{ selected: boolean }>`
    ${({ selected }) => `
        display: block;
        margin: 0 0 12px;
        padding: 16px;
        border-radius: 12px;

        &:last-child {
            margin-bottom: 48px;
        }

        ${selected ? `
            border: 2px solid var(--light-green);
            padding: 15px;
            h5 {
                color: var(--light-green);
            }
        ` : `
            border: 1px solid var(--light-green);
        `}
        
        @media screen and (min-width: 768px) {
            padding: 24px;
        }
    `}
`

const Header = styled.header`
    display: grid;
    grid-template-columns: 5fr 2fr;
    column-gap: 16px;
`

const Title = styled.h5`
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 8px;
`

const Description = styled.div<{ addPadding: boolean }>`
    ${({ addPadding }) => `
        grid-column: 1 / 2;

        grid-row: 3 / 4;
        
        ul {
            padding-inline-start: 16px;
        }
        @media screen and (min-width: 768px) {
            margin: 0 0 ${addPadding ? '24px' : '0px'};
            
            ul {
                padding-inline-start: 32px;
            }
        }
    `}
`

const TagContainer = styled.div`
    margin: 16px 0 0;
`

const Input = styled.input`
    display: none;
`

export interface SubscriptionBlockProps {
    bundle: BundlePB;
    locale: string
    currency: string
    translations: PageContext['translations'];
};

const SubscriptionBlock = ({
    bundle,
    locale,
    currency,
    translations,
}: SubscriptionBlockProps) => {
    const [selectedBundle, lang] = useAppSelector(state => [state.filter.selectedBundle, state.general.lang]);
    const dispatch = useAppDispatch();
    const { bundleId } = bundle || {};

    const selected = bundleId === selectedBundle;

    const setNewBundle = () => dispatch(setBundle(bundleId as string));

    const name = lang === 'de-eu' ? bundle.nameOrderDe : bundle.nameOrderEn;
    const description = lang === 'de-eu' ? bundle.descriptionDe : bundle.descriptionEn;
    
    if (selected) console.log('bundle =>', bundle);

    return (
        <Label selected={selected} onClick={setNewBundle}>
            <Header>
                {name && <Title>{name}</Title>}
                <Description addPadding={selected} dangerouslySetInnerHTML={{ __html: description }}/>
                <SubscriptionBlockPricesWithFilter
                    bundle={bundle}
                    translations={translations}
                    currency={currency}
                    locale={locale}
                />
            </Header>
            <Input
                type="radio"
                value={bundleId as string}
            />
            {selected && bundle && <BundleListWithFilter items={bundle.itemsExtended} />}
            {(
                <TagContainer>
                    <TagSectionWithFilter locale={locale} tags={bundle.labels} />
                </TagContainer>
            )}
        </Label>
    )
}

export default SubscriptionBlock;