import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Wrapper from "../../../layout/wrapper";
import Button from "../../../snippets/button";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { BundlePB, BundlesConfig, SliceProps } from "../../types";
import { hasRichText, renderRich, isDark, getFormattedProductQueryString, convertPrice } from "@utils";
import { useAppSelector } from "../../../../app/hooks";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const Heading = styled.div`
    margin: 0 0 16px;
    max-width: 753px;

    h2 a {
        font-size: 40px;
        line-height: 72.8px;
        font-weight: 400;
        
        &:after { 
            content:"→"
        }

        @media screen and (min-width: 768px) {
            font-size: 56px;
        }
    }
`

const Description = styled.div`
    margin: 0 0 40px 0;
    
    @media screen and (min-width: 768px) {
        margin: 0 0 64px 0;
        max-width: 848px;
    }
`
// column_layout, background_color, use_gradient,
const InnerWrapper = styled.div<{ numberOfItems: number }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 24px;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    // ${({ numberOfItems }) => numberOfItems > 2 && `
    //     @media screen and (min-width: 768px) {
    //         grid-template-columns: repeat(2, 1fr);
    //     }

    //     @media screen and (min-width: 1200px) {
    //         grid-template-columns: repeat(3, 1fr);
    //     }
    // `}
`

const Product = styled.article<{ columnLayout: string, backgroundColor: string, useGradient: boolean }>`
    padding: 24px;
    margin-bottom: 48px;
    // box-shadow: 0 0 16px 0 rgba(0, 0, 0, 10%);
    gap: 20px;
    
    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-height: 524px;
        margin: 0;
        padding: 40px;

        // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0%);
        // transition: box-shadow 0.25s ease;

        // &:hover {
        //     box-shadow: 0 0 16px 0 rgba(0, 0, 0, 10%);
        // }
    }

    ${({ columnLayout, backgroundColor, useGradient }) => {
        const columnCSS = columnLayout === '1 column' ? '' : `
            @media screen and (min-width: 1024px) {
                align-items: center;
                grid-column: span 2;
                gap: 10%;
            }
        `

        const backgroundCSS = useGradient ? `
            background: radial-gradient(circle, rgba(149,168,186,1) 0%, rgba(108,128,146,1) 100%);
        ` : `
            background: ${backgroundColor};
        `

        return columnCSS + backgroundCSS;
    }}
}
`

const Image = styled(GatsbyImage)`
    display: flex !important;
    align-self: center;
    max-width: 50% !important;
    margin: 0 auto 24px;

    @media screen and (min-width: 768px) {
        margin: 0 auto 20px;
    }
    @media screen and (min-width: 1024px) {
        max-width: 100% !important;
    }
`

const Content = styled.div<{ textColor: string }>`
    ${({ textColor }) => `
        * {
            color: ${textColor};
        }
    `}
`

const Title = styled.div`
    margin: 0 0 16px;
`

const ProductDescription = styled.div`
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        margin-bottom: 24px;
    }
`

const From = styled.p`
    margin: 0 0 0;
`

const loadingKeyFrames = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

const loading = () =>
    css`
      ${loadingKeyFrames} 2s linear infinite;
    `

const Price = styled.h3<{ $loading: boolean }>`
    margin: 0 0 16px;

    ${({ $loading }) => $loading ? `
        opacity: .2;
        // position: relative;
        display: flex;
  
        &::after {
            content: '';
            display: block;
            // position: absolute;
            // top: 0%;
            // left: 0%;
            margin-top: 4px;  
            margin-left: 16px;
            width: 10px;
            height: 10px;
            border-radius: 50px;
            border: 5px solid grey;
            border-top-color: black;
            animation: ${loading};
        }
    ` : ``}
`

const getLowestPriceFromProductVariantBundle = (b: any, currency: string) => {
    const { bundle } = b?.document?.data || {};

    return bundle?.[`price_amount_one_time_current_${currency}`]
}

const getLowestPriceFromProductVariant = (variant: any, currency: string) => {
    const { bundles, stripe_variant } = variant?.product_variant?.document?.data || {};

    return [
        ...(bundles?.map((b: any) => getLowestPriceFromProductVariantBundle(b?.bundle, currency)) || []),
        stripe_variant?.[`price_amount_pay_now_current_${currency}`],
    ]
}

const getLowestPriceFromLinkedProductDocument = (doc: any, currency: string) => {
    const variants = doc?.data?.prismic_product?.document?.data.variant || [];
    const prices = variants.reduce((r: any, v: any) => {
        return [...r, ...getLowestPriceFromProductVariant(v, currency)];
    }, [])?.sort();

    return prices[0] || 0;
}

const FeaturedProducts = (props: SliceProps<Queries.PrismicProductOverviewPageDataBodyFeaturedProducts>) => {
    const cart = useAppSelector(state => state.cart);
    const [bundles, setBundles] = useState<BundlePB[]>();
    const [config, setConfig] = useState<BundlesConfig>();
    const { context, slice, slices } = props;
    const { primary, items } = slice || {};
    const { heading, description, section_id } = primary;
    const { translations, page } = context;
    const [loading, setLoading] = useState(true);

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);

    const numberOfItems = items.length;

    useEffect(() => {
        fetch('/bundles/data.json').then(data => data.json()).then(async (data: BundlePB[]) => {
            const config = await fetch('/bundles/config.json').then(res => res.json()).then((bundlesConfig: BundlesConfig) => bundlesConfig);

            setBundles(data);
            setConfig(config);
            setLoading(false);
        });
    }, [])

    return (
        <Section id={section_id || ''}>
            <Wrapper>
                {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                {descriptionHasRichText && <Description>{renderRich(descriptionRichText)}</Description>}
                <InnerWrapper numberOfItems={numberOfItems}>
                    {items.map((
                        {
                            column_layout,
                            background_color,
                            use_gradient,
                            product_title,
                            product_image,
                            description,
                            button_text,
                            link,
                            bundle_preselect_index,
                        }: any,
                        index
                    ) => {

                        const [productTitleHasRichText, productTitleRichText] = hasRichText(product_title?.richText);
                        const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
                        const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

                        const lowestPrice = getLowestPriceFromLinkedProductDocument(link.document, cart.currency.toLowerCase());

                        const textColor = !!use_gradient ? 'white' : (
                            background_color ? (
                                isDark(background_color) ? 'white' : 'var(--dark-grey)'
                            ) : 'var(--dark-grey)'
                        );

                        const bundleFilter = link?.document?.data?.prismic_product?.document?.data.bundle_filter as string;

                        const filters = JSON.parse(bundleFilter || '{}');
                        const productType = filters.product;
                        const allowedTags = filters.allowed_tags;
                        const disallowedTags = filters.disallowed_tags;

                        const productConfig = config?.product_categories.find((category) => category.category === filters.product);

                        const colorConfig = productConfig?.tag_categories.find((category) => category.category === 'colors');
                        const colors = allowedTags?.filter((allowedTag: string) => colorConfig?.tags.includes(allowedTag)) || [];
                        const selectedColor = colors[0];
                        
                        const brushConfig = productConfig?.tag_categories.find((category) => category.category === 'brush');
                        const brushes = allowedTags?.filter((allowedTag: string) => brushConfig?.tags.includes(allowedTag)) || [];
                        const selectedBrush = brushes[0];

                        const sizeConfig = productConfig?.tag_categories.find((category) => category.category === 'volume');
                        const sizes = allowedTags?.filter((allowedTag: string) => sizeConfig?.tags.includes(allowedTag)) || [];
                        const selectedSize = sizes[0];

                        const typeConfig = productConfig?.tag_categories.find((category) => category.category === 'type');
                        const type = allowedTags?.find((allowedTag: string) => typeConfig?.tags.includes(allowedTag));
                        const hardwareType = type;

                        const filteredBundles = bundles?.filter((bundle) => (
                            (!!selectedColor ? bundle.tags.includes(selectedColor) : true) &&
                            (!!selectedBrush ? bundle.tags.includes(selectedBrush) : true) &&
                            (!!selectedSize ? bundle.tags.includes(selectedSize) : true) &&
                            (!!hardwareType ? bundle.tags.includes(hardwareType) : true) &&
                            (!!disallowedTags?.length
                                ? !bundle.tags?.reduce<boolean>((bool, tag) => {
                                    return disallowedTags.includes(tag) || bool;
                                }, false)
                                : true
                            )
                        ))

                        const capitalize = (str: string): string  => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                        const translationKey = capitalize(cart.currency);

                        const cheapestBundle = filteredBundles?.reduce((cheapBundle: BundlePB | null, newBundle) => {
                            if (!cheapBundle) {
                                return newBundle;
                                // @ts-ignore
                            } else if (newBundle[`priceAmountOneTimeCurrent${translationKey}`] < cheapBundle[`priceAmountOneTimeCurrent${translationKey}`]) {
                                return newBundle
                            } else {
                                return cheapBundle;
                            }
                        }, null);

                        // @ts-ignore
                        const bundleLowestPrice = cheapestBundle?.[`priceAmountOneTimeCurrent${translationKey}`] || 0;
                        const price = lowestPrice ? lowestPrice : bundleLowestPrice;

                        return (
                            <Product
                                key={`featured-product-${index}`}
                                columnLayout={column_layout || '1 column'}
                                backgroundColor={background_color || 'transparent'}
                                useGradient={!!use_gradient}
                            >
                                {product_image?.gatsbyImageData && (
                                    <Image
                                        image={(product_image.gatsbyImageData as unknown) as IGatsbyImageData}
                                        alt={product_image.alt || ''}
                                    />
                                )}
                                <Content textColor={textColor}>
                                    {productTitleHasRichText && <Title>{renderRich(productTitleRichText)}</Title>}
                                    {descriptionHasRichText && <ProductDescription>{renderRich(descriptionRichText)}</ProductDescription>}

                                    <From>{translations?.from}:</From>
                                    {typeof price === 'number' && <Price $loading={loading}>{convertPrice((price || 0), cart.locale, cart.currency)}</Price>}

                                    {link && link?.url && buttonTextHasRichText &&
                                        <Button
                                            url={
                                                link?.url
                                                    ? `${link.url}${getFormattedProductQueryString(
                                                        index,
                                                        section_id as unknown as undefined,
                                                        page,
                                                        bundle_preselect_index
                                                        )}`
                                                    : ''
                                            }
                                            buttonStyle={'solid'}
                                        >
                                            {renderRich(buttonTextRichText)}
                                        </Button>
                                    }
                                </Content>
                            </Product>
                        )
                    })}
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
}

export default FeaturedProducts