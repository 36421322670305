import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { getStars } from "@utils";
import ProductForm from './product-form';
import ProductFormWithFilter from './product-form-with-filter';
import { RichText } from "prismic-reactjs";
import TagSection from "./form-snippets/tag-section";
import ProductSlider from "./form-snippets/product-slider";
import { useAppSelector } from "../../../../app/hooks";
import { BundlePB, BundlesConfig, PageContext, Settings } from "../../../slices/types";
import ProductUpsell from "./product-upsell";

const Section = styled.section`
	
	@media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 45vw auto;
	}
`

const Images = styled.article`
    position: relative;
`

const SliderSticky = styled.div`
    .slick-disabled {
        opacity: 0;
    }
	@media screen and (min-width: 768px) {
		position: sticky;
        top: 0;
	}
`

const Product = styled.article<{ $useWhite: boolean }>`
    padding: 32px 16px 48px;
	
	@media screen and (min-width: 768px) {
        // padding: 145px 80px 48px 64px;
        padding: 145px 32px 64px;

        ${({ $useWhite }) => $useWhite ? `` : `
            background-color: var(--bg-light-grey-two);
        `}
	}
`

const Header = styled.header`
    margin: 0 0 8px;

    h1 {
        font-size: 24px;
    }
	
	@media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 8fr 2fr;

        h1 {
            font-size: 40px;
            font-weight: 400;
            line-height: 125%;
        }
	}
`

const StarsContainer = styled.div`
    display: flex;
    margin: 0 0 16px;
	
    svg {
        width: 12px;
        margin-right: 4px;
    }

	@media screen and (min-width: 768px) {

	}
`

const Description = styled.div`
    margin: 0 0 48px;
    
    p {
        
        @media screen and (min-width: 768px) {
            font-size: 20px;
            font-weight: 400;
            line-height: 125%;
        }
    }
`

const TagContainer = styled.div`
    margin: 0 0 12px;
`

const ProductSection = ({
    bundlesConfig,
    filterString,
    product,
    translations,
    location,
    settings,
    upsell = {},
    showFloatingSelector,
    slices,
    brushModal,
}: {
    bundlesConfig: BundlesConfig;
    bundles: BundlePB[];
    filterString?: string;
    product?: Queries.PrismicProductDataType;
    translations: PageContext['translations'];
    location: Record<string, unknown>;
    settings: Settings;
    upsell: {
        upsell_title?: string | null;
        upsell_description?: string | null;
        upsell_items?: Queries.PrismicProductPageDataUpsellItems[];
    }
    showFloatingSelector: boolean;
    slices: any[],
    brushModal: any;
}) => {
    const { description, star_rating, tags, title, variant, images, is_bundle } = product || {};
    const [
        selectedVariant,
        selectedBundle,
        selectedFilterBundle,
        audience
    ] = useAppSelector(state => [
        state.product.selectedVariant,
        state.product.selectedBundle,
        state.filter.selectedBundle,
        state.general.audience,
    ]);
    const [bundles, setBundles] = useState<BundlePB[]>([]);
    
    // temporarily force hide until popup size guide is implempented
    // const shouldShowCompareBrushes = slices.find((slice) => slice.slice_type === 'featured_brushes');
    const shouldShowCompareBrushes = false;

    const bundle = filterString && filterString !== ''
            ? bundles?.find(bundle => bundle.bundleId === selectedFilterBundle)
            : selectedVariant?.bundles?.find((item) => item?.bundle?._id === selectedBundle);
    const bundleImages = bundle?.images || bundle?.image ? [bundle?.image] : [];

    // @ts-ignore
    const [imageArray, setImageArray] = useState<Queries.PrismicProductDataType['images'] | string[]>([
        // ...(selectedVariant?.images?.map(image => ({ image })) || []),
        ...bundleImages,
        ...(images || []),
    ]);

    useEffect(() => {
        const bundle = filterString && filterString !== ''
            ? bundles?.find(bundle => bundle.bundleId === selectedFilterBundle)
            : selectedVariant?.bundles?.find((item) => item?.bundle?._id === selectedBundle);
        const bundleImages = bundle?.images || [];
        // const variantImages = selectedVariant?.images?.map(image => ({ image })) || []

        const newImageArray = [
            ...(bundleImages),
            // ...(variantImages),
            ...(images || []),
        ]

        // @ts-ignore
        setImageArray(newImageArray);
    }, [bundles, selectedVariant, selectedBundle, selectedFilterBundle]);

    useEffect(() => {
        fetch('/bundles/data.json').then(data => data.json()).then(async (data: BundlePB[]) => {
            setBundles(data);
        });
    }, []);

    return (
        <Section>
            <Images>
                <SliderSticky>
                    {images && (
                        // @ts-ignore
                        <ProductSlider images={imageArray} />
                    )}
                </SliderSticky>
            </Images>
            <Product $useWhite={audience === 'adults'}>
                {tags?.length && (
                    <TagContainer>
                        <TagSection tags={tags as Queries.PrismicProductDataTags[]} />
                    </TagContainer>
                )}
                <Header>
                    {title?.richText && RichText.render(title.richText)}
                </Header>
                {(!!star_rating && star_rating > 0) && (
                    <StarsContainer>
                        {getStars(star_rating)}
                        <p><a href="www.google.com">review</a></p>
                    </StarsContainer>
                )}
                <Description className='rt'>
                    {description?.richText && RichText.render(description.richText)}
                </Description>
                {filterString ? (
                    <ProductFormWithFilter
                        filterString={filterString}
                        translations={translations}
                        location={location}
                        settings={settings}
                        shouldShowCompareBrushes={shouldShowCompareBrushes}
                        showFloatingSelector={showFloatingSelector}
                        brushModal={brushModal}
                    />
                ) : (
                    <ProductForm
                        title={title?.text as string}
                        variants={variant as Queries.PrismicProductDataVariant[]}
                        isBundle={is_bundle as boolean}
                        translations={translations}
                        location={location}
                        settings={settings}
                        shouldShowCompareBrushes={shouldShowCompareBrushes}
                        showFloatingSelector={showFloatingSelector}
                    />
                )}
                <ProductUpsell
                    title={upsell?.upsell_title}
                    description={upsell?.upsell_description}
                    items={upsell.upsell_items}
                    location={location}
                    translations={translations}
                    settings={settings}
                />
            </Product>
        </Section>
    )
}

export default ProductSection;
