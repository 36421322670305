export type Audience = 'kids' | 'adults';
export type Locale = 'de-eu' | 'en-gb';

export const constants = {
    cookies: {
        YOUTUBE_COOKIE: '__pb-yt-cnsnt',
    },
    audience: {
        KIDS: 'kids' as Audience,
        ADULTS: 'adults' as Audience,
    },
    locale: {
        DE_EU: 'de-eu' as Locale,
        EN_GB: 'en-gb' as Locale,
    },
    page: {
        type: {
            PRODUCT_OVERVIEW_PAGE: 'product_overview_page',
            PRODUCT_PAGE: 'product_page',
        }
    }
}