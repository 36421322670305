import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userTimeZoneToCountryCode } from '@utils';

import { Cart as CartInterface } from '../../utils/playbrush-client/types';
import { setCartAction, setCurrencyAction, setCountryAction, setLanguageAction } from './cart-actions';
import { InitialState } from './cart-types';

const initialState: InitialState = {
    open: false,
    cartId: '',
    currency: 'eur',
    language: 'DE',
    country: 'DE',
    lineItems: [],
    couponCode: '',
    amountSubtotal: 0,
    amountTotal: 0,
    amountDiscount: 0,
    amountShipping: 0,
    amountTotalAfter: 0,
    amountTax: 0,
    lineItemCount: 0,
    locale: 'de-EU',
    currencyCode: 'EUR',
    subtotal: '€0.00',
    total: '€0.00',
    discount: '€0.00',
    shipping: '€0.00',
    tax: '€0.00',
    frontendData: '',
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart: (state, action: PayloadAction<CartInterface>) => {
            return setCartAction(state, action);
        },
        setCountry: (state, action: PayloadAction<string>) => {
            return setCountryAction(state, action);
        },
        setCurrency: (state, action: PayloadAction<string>) => {
            return setCurrencyAction(state, action);
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            return setLanguageAction(state, action);
        },
        setInitialState: (state) => {
            return { ...state, ...initialState };
        },
        setDrawerState: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
            return state;
        },
    }
})

export const {
    setCart,
    setCountry,
    setCurrency,
    setLanguage,
    setInitialState,
    setDrawerState
} = cartSlice.actions
export default cartSlice.reducer