import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../../../app/hooks";
import { BundlePB, Translations } from "../../../../slices/types";
import { convertPrice } from "@utils";

const Selector = styled.button<{ selected: boolean }>`
    display: flex;
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${({ selected }) => selected ? 'var(--dark-green)' : '#A2A3A6'};
    padding: 16px;
    margin: 0 0 16px;

    ${({ selected }) => selected
    ? `
        background-color: #F5F5F5;
        border: 2px solid var(--dark-green);
        box-shadow: 0px 1px 10px 0px #00000026;

        & > h5 {
            font-weight: 700 !important;
        }
    `
    : `
        background-color: white;
        border: 1px solid #A2A3A6;

        & > h5 {
            color: #A2A3A6;
        }
    `}
`

const Input = styled.div<{ selected: boolean }>`
    width: 19px;
    height: 19px;
    flex: 0 0 19px;
    position: relative;
    border-radius: 50%;
    margin: 0 8px 0 0;

    ${({ selected }) => selected
    ? `
        border: 2px solid var(--dark-green);

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 3px;
            right: 3px;
            border-radius: 50%;
            width: 13px;
            height: 13px;
            background-color: var(--dark-green);
        }
    `
    : `
        border: 2px solid #A2A3A6;
    `}
`

const textStyle = `
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
`

const Price = styled.h5`
    ${textStyle}
    margin-right: 16px;
    font-weight: 700 !important;
`

const Title = styled.h5`
    ${textStyle}
    font-weight: 400;
`

const Pill = styled.p`
    font-size: 0.75rem;
    font-weight: 400 !important;
    line-height: 1rem;
    background-color: var(--dark-green);
    color: white !important;
    padding: 4px 8px;
    border-radius: 16px;
    margin: 0 0 0 10px;
`


const capitalize = (str: string): string  => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export interface SubscriptionSelectorFilterItemProps {
    bundle: BundlePB;
    title: string
    currentType: 'subscription' | 'one-time';
    type: 'subscription' | 'one-time';
    click: (str: 'subscription' | 'one-time') => void;
    locale: string;
    translations: Translations;
};

const SubscriptionSelectorFilterItem = ({ bundle, title, currentType, type, click, locale, translations }: SubscriptionSelectorFilterItemProps) => {
    const currency = useAppSelector(state => state.cart.currency);
    const selected = currentType === type;
    
    // @ts-ignore
    const priceOneTime = bundle?.['priceAmountOneTimeCurrent'+capitalize(currency)] || 0;

    // @ts-ignore
    const pricePayNow = bundle?.['priceAmountPayNowCurrent'+capitalize(currency)] || 0;

    // @ts-ignore
    const pricePayNowCrossed = bundle?.['priceAmountPayNowCrossed'+capitalize(currency)] || 0;

    // @ts-ignore
    const savings = bundle?.['savings'+capitalize(currency)] || 0;

    const price = convertPrice((priceOneTime > 0 ? priceOneTime : pricePayNow), locale, currency);

    return (
        <Selector type="button" selected={selected} onClick={() => click(type)}>
            <Input selected={selected} />
            <Price>{price}</Price>
            <Title>{title}</Title>
            {pricePayNowCrossed > 0 && savings !== 'N/A %' && savings !== '0 %' && <Pill>{`${translations.save} ${savings}`}</Pill>}
        </Selector>
    )
}

export default SubscriptionSelectorFilterItem;