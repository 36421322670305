import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { convertPrice } from "../../../../../utils";
import Button from "../../../../snippets/button";


const AddToCart = (props: { isLoading: boolean, price: number, buttonText: string }) => {
    const [locale, currency] = useAppSelector(({ cart }) => [cart.locale, cart.currency]);
    const { isLoading, price = 0, buttonText } = props;
    return (
        <Button type="submit" loading={isLoading}>
            {buttonText} — {convertPrice(price, locale, currency)}
        </Button>
    )
}

export default AddToCart;