import React from "react";
import styled from "styled-components";
import PlusIcon from "../../../../icons/plus-icon";
import BundleListItemWithFilter from "./bundle-list-item-with-filter";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 24px;
    flex-wrap: wrap;
    gap: 32px;

	@media screen and (min-width: 768px) {
        margin-top: 8px;
	}
`

const IconContainer = styled.div`
    display: none;
    align-items: center;
    height: 64px;
    margin: 0 8px;
	
	@media screen and (min-width: 768px) {
        // display: flex;
	}
`

const BundleList = ({ items }: any) => {

    return (
        <Container>
            {items?.map(({ product }: any, index: number) => (
                <React.Fragment key={index}>
                    {index !== 0 ? (
                        <IconContainer>
                            <PlusIcon />
                        </IconContainer>
                    ) : null}
                    <BundleListItemWithFilter item={product} />
                </React.Fragment>
            ))}
        </Container>
    )
}

export default BundleList;