import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SubscriptionBlockWithFilter from "./subscription-block-with-filter";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { BundlePB, BundlesConfig, Translations } from "../../../../../components/slices/types";
import SubscriptionSelectorFilterItem from "./subscription-selector-filter-item";
import { setBundle } from "../../../../../app/slices/filter-reducer";

const Container = styled.div``

const Fieldset = styled.fieldset`
    border: 0;
    padding: 0;
`

const Header = styled.header`
    margin: 0 0 16px;
    display: flex;
    justify-content: space-between;
`

const BlockTitle = styled.h5`
    margin: 0;
    font-size: 1.125rem;
    font-weight: 600;
`

const SubscriptionSelectorFilter = styled.div`
    margin: 0 0 40px;
`

const getPreselectIndexFromSearchParams = (location: { search?: string }): number => {
    const preselectMatchData = ((location.search || '') as string)?.match(/preselect=([^&]+)/);
    const preselect = parseInt(preselectMatchData ? preselectMatchData[1] : '1');

    return preselect;
}

type PreselectType = 'subscription' | 'one-time';

const getTypePreselectFromSearchParams = (location: { search?: string }): PreselectType => {
    const preselectMatchData = ((location.search || '') as string)?.match(/preselect_type=([^&]+)/);
    const preselect = (preselectMatchData ? preselectMatchData[1] : 'subscription') as PreselectType;

    return preselect;
}

export interface SubscriptionSelectorProps {
    bundles: BundlePB[];
    config: BundlesConfig;
    filter?: any;
    locale: string;
    location: Record<string, unknown>;
    currency: string
    translations: Translations;
};

const SubscriptionSelector = ({ config, filter, locale, currency, translations, location }: SubscriptionSelectorProps) => {
    const filterObject = JSON.parse(filter || "{}");
    const preselectIndex = filterObject?.preselect_index ? filterObject.preselect_index : getPreselectIndexFromSearchParams(location);
    const typePreselect = filterObject?.preselect_type ? filterObject.preselect_type : getTypePreselectFromSearchParams(location);

    const [
        bundles,
        disallowedTags,
        hardwareType,
        selectedColor,
        selectedBrush,
        selectedSize,
    ] = useAppSelector((state) => [
        state.filter.bundles,
        state.filter.disallowedTags,
        state.filter.hardwareType,
        state.filter.selectedColor,
        state.filter.selectedBrush,
        state.filter.selectedSize,
    ]);
    const dispatch = useAppDispatch();
    const [bundleType, setBundleType] = useState<PreselectType>(typePreselect);

    const [subscriptionBundles, oneTimeBundles] = bundles.reduce<BundlePB[][]>((bundles, bundle) => {
        const isInfilter = (
            (!!selectedColor ? bundle.tags.includes(selectedColor) : true) &&
            (!!selectedBrush ? bundle.tags.includes(selectedBrush) : true) &&
            (!!selectedSize ? bundle.tags.includes(selectedSize) : true) &&
            (!!hardwareType ? bundle.tags.includes(hardwareType) : true) &&
            (!!disallowedTags.length
                ? !bundle.tags.reduce<boolean>((bool, tag) => {
                    return disallowedTags.includes(tag) || bool;
                }, false)
                : true
            )
        )

        if (isInfilter) {
            if (bundle.tags.includes('subscription')) {
                bundles[0] = [...bundles[0], bundle]
            } else {
                bundles[1] = [...bundles[1], bundle]
            }
        }

        return bundles;
    }, [[], []]);

    const filteredBundles = bundleType === 'subscription' ? subscriptionBundles : oneTimeBundles
    const hasBundleSplit = subscriptionBundles.length && oneTimeBundles.length;

    const updateBundleType = (type: 'subscription' | 'one-time') => {
        setBundleType(type);
    }

    useEffect(() => {
        let bundleId;

        if (hasBundleSplit) {
            if (bundleType === 'subscription') {
                bundleId = subscriptionBundles[preselectIndex - 1]?.bundleId || subscriptionBundles[0]?.bundleId;
            } else {
                bundleId = oneTimeBundles[preselectIndex - 1]?.bundleId || oneTimeBundles[0]?.bundleId;
            }
        } else {
            bundleId = subscriptionBundles[preselectIndex - 1]?.bundleId || subscriptionBundles[0]?.bundleId;
        }

        dispatch(setBundle(bundleId));
    }, [bundleType, selectedColor, selectedBrush, selectedSize])

    const shouldShowBundles = filteredBundles && (bundleType === 'one-time' ? filteredBundles.length > 1 : true)

    return (
        <Container>
            {hasBundleSplit ? (
                <>
                    <Header>
                        <BlockTitle>{translations.choose_subscription_type}</BlockTitle>
                    </Header>
                    <SubscriptionSelectorFilter>
                        <SubscriptionSelectorFilterItem
                            bundle={subscriptionBundles[0]}
                            title={translations.select_subscription}
                            currentType={bundleType}
                            type={'subscription'}
                            click={updateBundleType}
                            locale={locale}
                            translations={translations}
                        />
                        <SubscriptionSelectorFilterItem
                            bundle={oneTimeBundles[0]}
                            title={translations.select_one_time}
                            currentType={bundleType}
                            type={'one-time'}
                            click={updateBundleType}
                            locale={locale}
                            translations={translations}
                        />
                    </SubscriptionSelectorFilter>
                </>
            ) : null}
            {shouldShowBundles && (
                <>
                    <Header>
                        <BlockTitle>{translations.select_plan}</BlockTitle>
                    </Header>
                    <Fieldset id="bundle">
                        {filteredBundles.map((bundle) => (
                            <SubscriptionBlockWithFilter
                                key={bundle.bundleId}
                                bundle={bundle}
                                locale={locale}
                                currency={currency}
                                translations={translations}
                            />
                        ))}
                    </Fieldset>
                </>
            )}
        </Container>
    )
}

export default SubscriptionSelector;