import React from "react"
import styled from "styled-components";
import { LineItem } from "../../../../../../utils/playbrush-client/types";
import { useAppSelector } from "../../../../../../app/hooks";
import { PageContext } from "../../../../../slices/types";
import { convertPrice } from "../../../../../../utils";

import { concatSubscriptionPrefix, concatTrialText, getSubscriptionPrice, hasFreeTrial, splitLineItemContent } from "../../../../../../utils/cart/line-items";

const GAP = 24;

const PriceLine = styled.div`
    margin: 0 0 0 ${GAP}px;
    font-size: 12px;
    text-align: right;
    display: flex;
    flex-direction: column;
    text-align: right;
`;

const DiscountedPrice = styled.span`
    margin: 0 0 0 4px;
    text-decoration: line-through;
    font-size: 12px;
    opacity: 0.4;
`;

const Price = styled.span`
    margin: 4px 0;
    font-size: 16px;
    font-weight: bold;
`;

const PriceSubLine = styled.div`
    display: block;
`;

const SubscriptionPrefix = styled.span`
    margin: 0 8px 0 0;
    font-size: 12px;
`;

const PriceWrapper = styled.div`
    display: block;
`;

interface LineItemSubscriptionProps {
    lineItem: LineItem;
    translations: PageContext['translations'];
    trial: boolean;
    quantity: number;
    thankYouPage?: boolean;
    isSubscription: boolean;
}

const LineItemSubscription = ({ lineItem, trial, translations, quantity, thankYouPage, isSubscription }: LineItemSubscriptionProps) => {
    const { subscriptionItems } = splitLineItemContent(lineItem.lineContent);
    const { price, renewalPrice } = getSubscriptionPrice(subscriptionItems);
    const cart = useAppSelector(state => state.cart);
    const { locale, currency } = cart;

    const trialText = concatTrialText(lineItem, translations, locale, currency);
    const subscriptionPrefix = concatSubscriptionPrefix(lineItem, translations);

    return (
        <PriceLine>
            {hasFreeTrial(lineItem) ? (
                <>
                    <Price>
                        {convertPrice((0), locale, currency)}
                    </Price>
                    <PriceSubLine>
                        {translations.free_month}
                        <div>
                            {trialText}
                        </div>
                    </PriceSubLine>
                </>
            ) : (
                <>
                    {/* {lineItem.priceDefaultAmount > lineItem.priceAmount && (
                        <DiscountedPrice>
                            {convertPrice(((lineItem.priceDefaultAmount || 0) * quantity), locale, currency)}
                        </DiscountedPrice>
                    )} */}
                    <PriceWrapper>
                        {isSubscription && (
                            <SubscriptionPrefix>
                                {subscriptionPrefix}
                            </SubscriptionPrefix>
                        )}
                        <Price>
                            {convertPrice(((price || 0) * quantity), locale, currency)}
                        </Price>
                    </PriceWrapper>
                </>
            )}
        </PriceLine>
    )
};

export default LineItemSubscription;